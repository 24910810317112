<template>
    <div>
        <CSDialog
                :dialogTitle="dialogTitle"
                dialogWidth="708px"
                :dialogVisible="addRoomVisibleHy"
                @onClose="addRoomVisibleHy = false"
                @onConfirm="editRoomHy"
                dialog-confirm-btn-text="保存"
                @click="
        () => {
          electricVisible = false;
          wrateVisible = false;
        }
      "
        >
            <div slot="dialog-content" class="room-form">
                <div class="field-item">
                    <label class="field-item-label"> 水表 </label>
                    <div
                            class="field-item-content"
                            style="position: relative"
                            @click.stop="
              () => {
                wrateVisible = !wrateVisible;
                selectInput = '';
                electricVisible = false;
              }
            "
                    >
                        <CSSelect height="40px" i-width="36px">
                            <div
                                    :style="{ width: '196px', padding: '0 6px' }"
                                    class="field-font"
                            >
                                <template v-if="editRoomInfo.water.length > 0"
                                ><span style="color: black"
                                >已选择水表{{ editRoomInfo.water.length }}个</span
                                ></template
                                >
                                <template v-else>
                                    <option value="">请选择</option>
                                </template>
                            </div>
                            <div>
                                <TreePanel
                                        v-show="wrateVisible"
                                        style="z-index: 100; overflow: hidden"
                                >
                                    <div>
                                        <div class="location-search">
                                            <input
                                                    type="text"
                                                    placeholder="搜索水表编号"
                                                    class="location-input"
                                                    style="width: 260px"
                                                    v-model="selectInput"
                                            />
                                            <button
                                                    class="btn btn-primary font"
                                                    @click="getAllWaterMeters"
                                            >
                                                查询
                                            </button>
                                        </div>
                                    </div>
                                    <EditWaterEven
                                            style="height: 100%; overflow-y: scroll"
                                            :tree="wrateVisibleList"
                                            @click="changeStaff"
                                            textName="imei"
                                            :showCheckbox="true"
                                    />
                                </TreePanel>
                            </div>
                        </CSSelect>
                    </div>
                </div>

                <div class="field-item">
                    <label class="field-item-label"> 电表 </label>
                    <div
                            class="field-item-content"
                            style="position: relative"
                            @click.stop="
              () => {
                electricVisible = !electricVisible;
                electricMeterNo = '';
                wrateVisible = false;
              }
            "
                    >
                        <CSSelect height="40px" i-width="36px">
                            <div
                                    :style="{ width: '196px', padding: '0 6px' }"
                                    class="field-font"
                            >
                                <template v-if="editRoomInfo.electricity.length > 0"
                                ><span style="color: black"
                                >已选择电表{{ editRoomInfo.electricity.length }}个</span
                                ></template>
                                <template v-else>
                                    <option value="">请选择</option>
                                </template>
                            </div>
                            <div>
                                <TreePanel v-show="electricVisible" style="z-index: 100">
                                    <div>
                                        <div class="location-search">
                                            <input
                                                    type="text"
                                                    placeholder="搜索电表编号"
                                                    class="location-input"
                                                    style="width: 260px"
                                                    v-model="electricMeterNo"
                                            />
                                            <button
                                                    class="btn btn-primary font"
                                                    @click="getElectricityMeterList"
                                            >
                                                查询
                                            </button>
                                        </div>
                                    </div>
                                    <EditWaterEven
                                            style="height:100%;overflow-y:scroll"
                                            :tree="electricVisibleList"
                                            @click="changeStaffElectric"
                                            textName="imei"
                                            :showCheckbox="true"
                                    />
                                </TreePanel>
                            </div>
                        </CSSelect>
                    </div>
                </div>
            </div>
        </CSDialog>
    </div>
</template>
<script>
    import {
        createRoomUrl,
        editRoomUrl,
        queryBuildingUrl,
        getAllWaterMetersUrl,
        isHydropowerBindingUrl,
        hydropowerModifyBindingUrl,
        selectWaterMeterRelevanceUrl,
        queryWaterStateWUrl,
        selectRecordUrl
    } from "@/requestUrl";
    import TreePanel from "@/components/common/TreePanel";
    import EditWaterEven from "@/components/EditWaterEven";
    import CSSelect from "@/components/common/CSSelect";
    import CSDialog from "@/components/common/CSDialog";
    import {HYDROPOWER} from "@/constant";
    import {cloneDeep} from "lodash";

    export default {
        props: {},
        components: {
            CSSelect,
            CSDialog,
            EditWaterEven,
            TreePanel,
        },
        activated() {
            this.$vc.on("editHydroprwer", "openEditHydroprwer", (_room) => {
                this.selectInput = "";
                this.electricMeterNo = "";
                this.getAllWaterMeters();
                this.getElectricityMeterList();
                if (_room?.room) {
                    this.selectWartList = _room.room;
                }
                try {
                    if (_room.title) {
                        this.dialogTitle = _room.title;
                    }
                } catch {
                }

                this.refreshEditRoomInfo();
                this.addRoomVisibleHy = true;
            });
        },
        data() {
            return {
                selectInput: "", //查询表
                electricMeterNo: "",
                waterKey: [], //水表id
                electricVisible: false,
                wrateVisible: false, //随表下拉显示
                wrateVisibleList: [], //水表列表
                electricVisibleList: [], //电表列表
                dialogTitle: "添加房间",
                editRoomInfo: {
                    regionId: this.$vc.getCurrentRegion().communityId,
                    water: [],
                    electricity: [],
                },
                addRoomVisibleHy: false,
                selectWartList: [],
            };
        },
        mounted() {

        },
        methods: {
            async changeStaffElectric({key, source}) {
                var isS = await this.isHydropowerBinding(
                    source[key].id,
                    HYDROPOWER.WATT_HOUR_METER
                );

                var nameStr =
                    this.selectWartList.buildingName +
                    this.selectWartList.floor +
                    "层" +
                    this.selectWartList.roomNo;
                if (isS.data != "") {
                    if (nameStr == isS.data) {
                    } else if (isS.code == 0) {
                        source[key].allowCheck = false;
                        this.$vc.message(`该表已经与${isS.data}关联，无法选中`);
                        return;
                    }
                }
                const iSRes = await this.$fly.get(selectRecordUrl, {
                    id: source[key].id,
                });
                if (iSRes.code != 0) {
                    return;
                }
                if (source[key].checked) {
                    source[key].checked = false;
                    this.editRoomInfo.electricity = this.editRoomInfo.electricity.filter((item) => {
                        return item.checked;
                    });
                    return;
                }

                source[key].checked = true;
                this.editRoomInfo.electricity.push(source[key]);
            },
            //查询水电表是否被绑定
            isHydropowerBinding(ids, types) {
                return this.$fly
                    .get(isHydropowerBindingUrl, {
                        id: ids,
                        type: types,
                    })
                    .then((res) => {
                        return res;
                    });
            },
            //下拉框选中状态变化
            async changeStaff({key, source}) {
                var isS = await this.isHydropowerBinding(
                    source[key].id,
                    HYDROPOWER.WATER_METER
                );

                var nameStr =
                    this.selectWartList.buildingName +
                    this.selectWartList.floor +
                    "层" +
                    this.selectWartList.roomNo;
                if (isS.data != "") {
                    if (nameStr == isS.data) {
                    } else if (isS.code == 0) {
                        source[key].allowCheck = false;
                        this.$vc.message(`该表已经与${isS.data}关联，无法选中`);
                        return;
                    }
                }
                const iSRes = await this.$fly.get(queryWaterStateWUrl, {
                    id: source[key].id,
                });
                if (iSRes.code != 0) {
                    return;
                }
                if (source[key].checked) {
                    source[key].checked = false;
                    this.editRoomInfo.water = this.editRoomInfo.water.filter((item) => {
                        return item.checked;
                    });
                    return;
                }

                source[key].checked = true;
                this.editRoomInfo.water.push(source[key]);
            },
            //判断水表是否被选中
            isWartSelect(arr, value, editRoomList) {
                console.log('editRoomList');
                console.log(editRoomList);
                if (editRoomList) {
                    for (var i = 0; i < editRoomList.length; i++) {
                        if (editRoomList[i]?.imei == value) {
                            return true
                        }
                    }
                }
                if (!arr) {
                    return;
                }
                for (var i = 0; i < arr.length; i++) {
                    console.log('value');
                    console.log(value);
                    if (arr[i]?.no == value) {
                        return true;
                    }
                }


                return false;
            },
            //获取水表列表
            getAllWaterMeters() {
                this.$fly
                    .post(getAllWaterMetersUrl, {
                        regionCode: this.$vc.getCurrentRegion().code, //区域code
                        search: this.selectInput,
                    })
                    .then((res) => {
                        if (res.code != 0) {
                            return;
                        }
                        this.wrateVisibleList = res.data.map((item) => {
                            return {
                                ...item,
                                checked: this.isWartSelect(
                                    this.selectWartList.waterMeter,
                                    item.imei,
                                    this.editRoomInfo.water
                                )
                                    ? true
                                    : false,
                                allowCheck: true,
                            };
                        });
                        this.editRoomInfo.water = [
                            ...this.wrateVisibleList.filter((item) => {
                                return item.checked;
                            }),
                            ...this.editRoomInfo.water,
                        ];
                        var waterList = [];
                        var waterListImei = [];
                        for (var i = 0; i < this.editRoomInfo.water.length; i++) {
                            if (waterList.indexOf(this.editRoomInfo.water[i].imei) == -1) {
                                waterList.push(this.editRoomInfo.water[i].imei);
                                waterListImei.push(this.editRoomInfo.water[i]);
                            }
                        }
                        this.editRoomInfo.water = waterListImei;
                        console.log(this.wrateVisibleList);
                        console.log(this.editRoomInfo);
                    });
            },
            // 获取电表列表
            getElectricityMeterList() {
                this.$fly.post(selectWaterMeterRelevanceUrl, {
                    regionCode: this.$vc.getCurrentRegion().code, //区域code
                    search: this.electricMeterNo,
                })
                    .then(res => {
                        if (res.code != 0) {
                            return;
                        }
                        console.log(222222222222222);
                        this.electricVisibleList = res.data.map((item) => {
                            console.log('this.selectWartList.electricityMeter');
                            console.log(this.selectWartList.electricityMeter);
                            return {
                                ...item,
                                checked: this.isWartSelect(
                                    this.selectWartList.electricityMeter,
                                    item.imei,
                                    this.editRoomInfo.electricity
                                )
                                    ? true
                                    : false,
                                allowCheck: true,
                            };
                        });
                        this.editRoomInfo.electricity = [...this.electricVisibleList.filter((item) => {
                            return item.checked;
                        }), ...this.editRoomInfo.electricity];

                        let electricList = [];
                        let electricListImei = [];
                        for (let i = 0; i < this.editRoomInfo.electricity.length; i++) {
                            if (electricList.indexOf(this.editRoomInfo.electricity[i].imei) == -1) {
                                electricList.push(this.editRoomInfo.electricity[i].imei);
                                electricListImei.push(this.editRoomInfo.electricity[i]);
                            }
                        }
                        this.editRoomInfo.electricity = electricListImei;
                    })

            },
            //保存提交
            editRoomHy: function () {
                this.editRoomInfo.water = this.editRoomInfo.water.map((item) => {
                    return {id: item.id, no: item.imei};
                });
                this.editRoomInfo.electricity = this.editRoomInfo.electricity.map(item => {
                    return {id: item.id, no: item.imei}
                })
                this.$fly
                    .post(hydropowerModifyBindingUrl, {
                        id: this.selectWartList.roomId, //房间id
                        waterNo: this.editRoomInfo.water,
                        electricityNo: this.editRoomInfo.electricity,
                    })
                    .then((res) => {
                        if (res.code != 0) {
                            return;
                        }
                        this.$vc.toast("修改成功");
                        this.addRoomVisibleHy = false;
                        this.$vc.emit("room", "listRoom", null);
                    });
            },
            refreshEditRoomInfo: function () {
                this.editRoomInfo = {
                    regionId: this.$vc.getCurrentRegion().communityId,
                    water: [],
                    electricity: [],
                };
            },
        },
    };
</script>
<style lang="stylus" scoped>
    .room-form {
        padding: 27px 30px;

        .field-item {
            margin-bottom: 22px;
            font-size: 0;

            &-label {
                font-size: 24px;
                text-align: right;
                width: 208px;
                margin-right: 40px;
                display: inline-block;
                vertical-align: middle;
                line-height: 1;
                margin-bottom: 0;
            }

            &-content {
                display: inline-block;
                vertical-align: middle;
                font-size: 24px;

                input {
                    width: 400px;
                    height: 40px;
                    text-indent: 5px;
                    border-radius: 4px;
                    border: 1px solid #979797;

                    &::placeholder {
                        color: #999;
                    }
                }

                select {
                    width: 220px;
                    text-indent: 5px;
                }
            }
        }

        .tips {
            color: #999;
            font-size: 20px;
        }

        .field-font {
            font-size: 24px;
            font-family: PingFang SC;
            font-weight: 400;
            line-height: 33px;
            color: #999999;
        }
    }

    .location-search {
        width: 100%;
        display: flex;
    }

    .location-search .location-input {
        border-radius: 4px;
        border: 1px solid #999;
        padding: 0 10px;
        vertical-align: middle;
        width: 30px;
        height: 40px;
        box-sizing: border-box;
    }

    .font {
        font-size: 20px;
        vertical-align: middle;
        margin-left: 30px;
        float: right;
        height: 40px;
        padding: 0;
        width: 60px;
        line-height: 40px;
        box-shadow: 0 5px 10px 0 rgba(0, 182, 148, 0.3);
    }
</style>
